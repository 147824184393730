<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    *ngIf="isLoggedIn$ | async"
    class="mat-elevation-z4"
    [opened]="navOpen$ | async"
    (closed)="closeSideNav()"
    [mode]="(mobileNav$ | async) === true ? 'over' : 'side'"
  >
    <mat-toolbar class="logo-toolbar">
      <img
        class="main-logo"
        src="static/assets/images/hkstp-logo.svg"
        alt="HKSTP"
      />
    </mat-toolbar>
    <mat-nav-list>
      <ng-container
        *ngIf="
          (organizationsInitialLoad$ | async) &&
            (organizations$ | async)?.length === 0;
          else showOrgMenu
        "
      >
        <a
          mat-menu-item
          id="create-new-link"
          routerLink="/organizations/new"
          class="no-org-create"
        >
          Create New Organization
        </a>
      </ng-container>
      <ng-template #showOrgMenu>
        <mat-list-item
          id="org-dropdown"
          color="accent"
          [disabled]="(organizationsInitialLoad$ | async) === false"
          [matMenuTriggerFor]="menu"
          class="org-dropdown-list-item"
        >
          <span class="overflow">
            {{ (activeOrganizationDetail$ | async)?.name }}
          </span>
          <span class="down-caret">▼</span>
        </mat-list-item>
      </ng-template>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let organization of organizations$ | async"
          (click)="setOrganization(organization.id)"
          mat-menu-item
        >
          {{ organization.name }}
        </button>
        <!--
        <mat-divider></mat-divider>
        <a mat-menu-item id="create-new-link" routerLink="/organizations/new">
          Create New Organization
        </a>
        -->
      </mat-menu>
      <!-- 
      <a
        mat-list-item
        [disabled]="!activeOrganizationLoaded"
        [routerLink]="[activeOrganizationSlug, 'projects']"
        routerLinkActive="active-route"
      >
        Projects
      </a> 
      -->
      <a
        mat-list-item
        [disabled]="!activeOrganizationLoaded"
        [routerLink]="[activeOrganizationSlug, 'issues']"
        routerLinkActive="active-route"
      >
        Issues
      </a>
      <!-- 
      <a
        mat-list-item
        [disabled]="!activeOrganizationLoaded"
        [routerLink]="[activeOrganizationSlug, 'performance']"
        routerLinkActive="active-route"
      >
        Performance <span class="subdued">&nbsp;(Beta)</span>
      </a>
      //-->
      <a
        data-test-settings
        mat-list-item
        [disabled]="!activeOrganizationLoaded"
        [routerLink]="[activeOrganizationSlug, 'settings', 'projects', activeOrganizationSlug]"
        routerLinkActive="active-route"
      >
        Settings
      </a>
      <a
        class="sub-items"
        mat-list-item
        [disabled]="!activeOrganizationLoaded"
        [routerLink]="['/', activeOrganizationSlug, 'settings', 'projects']"
        routerLinkActive="active-route"
      >
        Projects
      </a>
      <a
        *ngIf="billingEnabled$ | async"
        class="sub-items"
        mat-list-item
        [routerLink]="['/', activeOrganizationSlug, 'settings', 'subscription']"
        routerLinkActive="active-route"
      >
        Subscription
      </a>
      <a
        mat-list-item
        [routerLink]="['/', activeOrganizationSlug, 'settings', 'teams']"
        routerLinkActive="active-route"
        class="sub-items"
      >
        Teams
      </a>
      <a
        mat-list-item
        [routerLink]="['/', activeOrganizationSlug, 'settings', 'members']"
        routerLinkActive="active-route"
        class="sub-items"
      >
        Members
      </a>
      <!--
      <mat-divider class="org-details-divider"></mat-divider>
      <a
        mat-list-item
        [routerLink]="['/profile']"
        routerLinkActive="active-route"
      >
        Profile
      </a>
      <a
        mat-list-item
        [routerLink]="['/profile']"
        routerLinkActive="active-route"
        class="sub-items"
      >
        Account
      </a>
      <a
        mat-list-item
        [routerLink]="['/profile', 'multi-factor-auth']"
        routerLinkActive="active-route"
        class="sub-items"
      >
        Multi-factor Authentication
      </a>
      <a
        mat-list-item
        [routerLink]="['/profile', 'notifications']"
        routerLinkActive="active-route"
        class="sub-items"
      >
        Notifications
      </a>
      <a
        mat-list-item
        [routerLink]="['/profile', 'auth-tokens']"
        routerLinkActive="active-route"
        class="sub-items"
      >
        Auth Tokens
      </a>
      <button mat-menu-item (click)="this.logout()">Log Out</button>
      -->
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content
    ><mat-toolbar
      *ngIf="isLoggedIn$ | async"
      (click)="toggleSideNav()"
      color="primary"
    >
      <button *ngIf="isLoggedIn$ | async" mat-button class="sidenav-toggle">
        <mat-icon>menu</mat-icon></button
      ><span
        class="active-org"
        *ngIf="activeOrganizationDetail$ | async as orgDetails"
        >{{ orgDetails?.name }}</span
      >
    </mat-toolbar>

    <div class="wrapper">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
